import { Component, OnInit, ViewChild } from '@angular/core';
import { StudentChangeService } from '../studentchange/studentchange.service';
import { formatDate, DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { Pipe } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { exit } from 'process';
import Swal from 'sweetalert2/dist/sweetalert2.js'

@Component({
  selector: 'studentchange',
  templateUrl: `studentchange.component.html`,  
})
export class StudentChangeComponent implements OnInit {
  @ViewChild('noofinstallments') inputName;
  pipe = new DatePipe('en-US');
  now = Date.now();
  GLBappFormStarEnd: string = "";
  admno: any;
  StudentShift: any;
  todayDate: any = this.pipe.transform(this.now, 'yyyy-MM-dd');
  Admdate: any;
  Efdate: any;
  Student: any = [];
  StudentDD: any;
  Fathermail: string = "";
  Mobile2: string = "";
  Mobile1: string = "";
  Father: string = "";
  Mother: string = "";
  SName: string = "";
  RollNo: string = "";
  
  public Courses: any;
  Category: any[];
  CheckBool: boolean;
  checkedM: string;
  checkedF: string;
  checkedO: string;
  checkedNew: string;
  checkedShiftM: string;
  checkedShiftE: string;
  checkedOld: string;
  UserForm: FormGroup;
  STUDADMNO: string = "";
  STUDADMNONEW: string = "";
  GLBappFormType: string = "";
  GLBappUserCode: string = "";
  UserName: any;
  date: string = '';
  GLBappFormName: string = "";
  isChecked: boolean = false;
  ByGender: string = '';
  ByShift: number = 0;
  ByType: string = '';
  IPAddress: string = "";
  constructor(private studService: StudentChangeService, private fb: FormBuilder, private router: Router) {  
    this.IPAddress = sessionStorage.getItem('ipaddress');
    this.Courses = "";
    this.GLBappFormName = "Enrolment Change";
    this.ByGender = 'M';
    this.ByType = 'F';
    this.StudentShift = "";
  }

  onClickedGender(event: any) {
    debugger;
    this.ByGender = 'M';
    console.log(event);
    switch (event) {
      case 'female':
        {
          this.ByGender = 'F';
          break;
        }
      case 'other':
        {
          this.ByGender = 'O';
          break;
        }      
      default:
        //for male
        this.ByGender = 'M';
        break;
    }
  }

  onClickedType(event: any) {
    debugger;
    this.ByType = 'F';
    switch (event) {
      case 'old':
        {
          this.ByType = 'R';
          break;
        }      
      default:
        //for new
        this.ByType = 'F';
        break;
    }
  }



  ngOnInit() {
    //debugger;
    this.GLBappFormStarEnd = sessionStorage.getItem('FYYear');
    if (parseInt(sessionStorage.getItem('usercode')) == 0 || sessionStorage.getItem('usercode') == null) {
      this.router.navigate(['/logoutuser'])
        .then(() => {
          window.location.reload();
        });
      return;
    }
    this.ByGender = 'M';
    this.ByType = 'F';
    this.UserForm = new FormGroup({
      //AId: new FormControl(null, Validators.required),
      SName: new FormControl(null, Validators.required),
      Admdate: new FormControl(null, Validators.required),
      Efadmdate: new FormControl(null, Validators.required),      
      Admno: new FormControl(null, Validators.required),
      AdmnoNew: new FormControl(null, Validators.required),
      RollNo: new FormControl(null, Validators.required),
      Cscode: new FormControl(null, Validators.required),
      Feemode: new FormControl(null, Validators.required),
      Instmode: new FormControl(null, Validators.required),
      StudTypes: new FormControl(null, Validators.required),
      Sex: new FormControl(null, Validators.required),
      Catcode: new FormControl(null, Validators.required),
      Father: new FormControl(null, Validators.required),
      Mother: new FormControl(null, Validators.required),
      Mobile1: new FormControl(null, Validators.required),
      Mobile2: new FormControl(null, Validators.required),
      iShiftId: new FormControl(null, Validators.required),
      IUserCode: new FormControl(null, Validators.required),
      Ycode: new FormControl(null, Validators.required),
      YearCode: new FormControl(null, Validators.required),
      ClientId: new FormControl(null, Validators.required),
    });
    this.studService.GetCourses().subscribe((courses: any) => {
      this.Courses = courses;
    }, (error) => {
      console.log(error);
    });
    this.studService.GetCategory().subscribe((category) => {
      this.Category = category;
    }, (error) => {
      console.log(error);
    });

    this.studService.GetChangeAllStudents().subscribe((StudentDD) => {
      this.StudentDD = StudentDD;      
    }, (error) => {
      //console.log(error);
    });
  }

  onTypesChange(value) {
    this.ByType = value;
    //console.log(" Value is : ", value);
  }

  onGenderChange(value) {
    this.ByGender = value;
    //console.log(" Value is : ", value);
  }

  onShiftChange(value) {
    this.ByShift = value;
    //console.log(" Value is : ", value);
  }

  onChangeNew(event: any) {
    debugger;    
    this.STUDADMNONEW = event.target.value;
  }

  onChange(event: any) {
    debugger;
    this.StudentShift = "";
    this.ByType = '';
    this.ByGender = '';
    this.ByShift = 0;
    this.Student = "";
    this.STUDADMNO = event.target.value;
    //this.todayDate = event.target.value;
    //console.log(this.STUDADMNO);
    this.checkedM = "";
    this.checkedF = "";
    this.checkedO = "";
    this.checkedNew = "";
    this.checkedOld = "";
    this.checkedShiftM = "";
    this.checkedShiftE = "";
    if (this.STUDADMNO.length != 0) {
      this.studService.GetStudentDET(this.STUDADMNO, parseInt(sessionStorage.getItem('YearCode')), parseInt(sessionStorage.getItem('iShiftId'))).subscribe((Student) => {        
        this.Student = Student;
        this.ByGender = this.Student.sex;
        //console.log(this.Student.sex);
        this.ByShift = this.Student.shiftId;
        this.ByType = this.Student.studType;
        this.Admdate = this.pipe.transform(this.Student.admdate, 'yyyy-MM-dd');
        this.Efdate = this.pipe.transform(this.Student.efadmdate, 'yyyy-MM-dd');
        if (Number(this.Student.shiftId) == 1) {
          this.StudentShift = "Morning";
        } else {
          this.StudentShift = "Evening";
        }
        if (this.Student.sex == 'M') {
          this.ByGender = 'M';
          this.checkedM = "checked";
          this.checkedF = "";
        } else if (this.Student.sex == 'F') {
          this.ByGender = 'F';
          this.checkedM = "";
          this.checkedF = "checked";
        } else {
          this.ByGender = 'O';
          this.checkedM = "";
          this.checkedF = "";
          this.checkedO = "checked";
        }
        if (Number(this.Student.shiftId) == 1) {
          this.checkedShiftM = "checked";
          this.checkedShiftE = "";
        } else {
          this.checkedShiftM = "";
          this.checkedShiftE = "checked";
        }
        if (this.Student.studType == 'F') {          
          this.checkedNew = "checked";
          this.checkedOld = "";
        } else {
          this.checkedNew = "";
          this.checkedOld = "checked";
        }
      }, (error) => {
        console.log(error);
      });
    }
  }


  onFormSubmit() {
    //Admno: 431915
    debugger;
    if (parseInt(sessionStorage.getItem('YesTran')) == 9) {
      Swal.fire({
        html: "<strong>Do some Action? Please change to current F/Year.</strong>",
        icon: 'info',
        showConfirmButton: true,
        confirmButtonColor: "#338921",
        confirmButtonText: 'OK'
      }).then((result) => {
        if (result.value) {
          //this.router.navigate(['/createdues'])
          //  .then(() => {
          //    window.location.reload();
          //  });
          //this.ngOnInit();
        }
      })
      return false;
    }
    this.CheckBool = true;

    this.studService.CheckEnrolExistYN(this.STUDADMNONEW).subscribe((response) => {
      if (response == 0) {
        this.studService.SPChangeStudAdmno(this.STUDADMNO, this.STUDADMNONEW, this.IPAddress, parseInt(sessionStorage.getItem('YearCode')), this.ByShift).subscribe((response) => {
          window.alert('Submitted successfully');
          this.router.navigate(['/studentchange'])
            .then(() => {
              window.location.reload();
            });
          this.ngOnInit();
        }, (error) => {
          console.log(error);
        });
      } else {
        window.alert('New Enrolment No already exist.');
      }
      }, (error) => {
        console.log(error);
      });
    }

    //Swal.fire({
    //  //position: 'top-end',        
    //  html: "<strong><H3>Do you want to Save the Record?</H3></strong>",
    //  showCancelButton: true,
    //  icon: 'question',      
    //  width: 400,
    //  padding: 50,
    //  //className: "green-bg",
    //  confirmButtonColor: "#338921",
    //  confirmButtonText: 'Yes',
    //  cancelButtonColor: "#F6122E",
    //  cancelButtonText: 'No'
    //}).then((result: { value: any; }) => {
    //  if (result.value) {
        
    //  }
    //})
  }

    //Swal.fire({
    //  //position: 'top-end',        
    //  html: "<strong><H3>Do you want to Save the Record?</H3></strong>",
    //  showCancelButton: true,
    //  icon: 'question',
    //  //iconHtml: '<img src="https://picsum.photos/100/100">',
    //  width: 400,
    //  padding: 50,
    //  //className: "green-bg",
    //  confirmButtonColor: "#338921",
    //  confirmButtonText: 'Yes',
    //  cancelButtonColor: "#F6122E",
    //  cancelButtonText: 'No'
    //}).then((result: { value: any; }) => {
    //  if (result.value) {
    //    this.studService.CheckEnrolExistYN(this.STUDADMNONEW, parseInt(sessionStorage.getItem('YearCode')), this.ByShift).subscribe((response) => {
    //      if (response == 0) {
    //        this.studService.SPChangeStudAdmno(this.STUDADMNO, this.STUDADMNONEW, this.IPAddress, parseInt(sessionStorage.getItem('YearCode')), this.ByShift).subscribe((response) => {
    //          Swal.fire({
    //            html: "<strong>Submitted successfully</strong>",
    //            icon: 'success',
    //            showConfirmButton: true,
    //            confirmButtonColor: "#338921",
    //            confirmButtonText: 'OK'
    //            //background: '#fff url(//bit.ly/1Nqn9HU)',
    //            //timer: 300000
    //          }).then((result) => {
    //            if (result.value) {
    //              this.router.navigate(['/studentchange'])
    //                .then(() => {
    //                  window.location.reload();
    //                });
    //              this.ngOnInit();
    //            }              
    //          });
    //        });
    //      }
    //    });
    //  }
    //});
    //}

    //this.studService.CheckEnrolExistYN(this.STUDADMNONEW, parseInt(sessionStorage.getItem('YearCode')), this.ByShift).subscribe((response) => {
    //  if (response == 0) {
    //    this.studService.SPChangeStudAdmno(this.STUDADMNO, this.STUDADMNONEW, this.IPAddress, parseInt(sessionStorage.getItem('YearCode')), this.ByShift).subscribe((response) => {
    //      window.alert('Submitted successfully');
    //      this.router.navigate(['/studentchange'])
    //        .then(() => {
    //          window.location.reload();
    //        });
    //      this.ngOnInit();
    //    }, (error) => {
    //      console.log(error);
    //    });        
    //  } else {
    //    window.alert('New Enrolment No already exist.');
    //  }
    //  }, (error) => {
    //    console.log(error);
    //  });
    //}
  //}

//  public SetGetNullValueStr(parametername: string) {
//    if (typeof parametername != 'undefined' && parametername) {
//      return parametername;
//    } else {
//      return "NULL";
//    }
//  }

//  public SetGetNullValueInt(parametername: any) {
//    if (typeof parametername != 'undefined' && parametername) {
//      return parametername;
//    } else {
//      return Number(0);
//    }
//  }

//  public SetGetNullValueDate(parametername: any) {
//    if (typeof parametername != 'undefined' && parametername) {
//      return parametername;
//    } else {
//      return '1900-01-01';
//    }
//  }
   
//  DeleteEmployee(data) {
//    if (window.confirm('Are you sure, you want to Delete?')) {
     
//    }
//  }
//}
