import '../polyfills';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';
import { StudentMobileDetpdfComponent } from './pdfreports/studmobiledet/studmobiledet.component';
import { StudentLedgerpdfComponent } from './pdfreports/studentledgerpdf/studentledgerpdf.component';
import { BoyGirlDetailListComponent } from './pdfreports/boygirldetaillist/boygirldetaillist.component';
import { IndividualStudentLedgerpdfComponent } from './pdfreports/individualstudentledgerpdf/individualstudentledgerpdf.component';
import { DuesCreatedPdfComponent } from './pdfreports/duescreatedpdf/duescreatedpdf.component';
import { LeftCreatedPdfComponent } from './pdfreports/leftcreatedpdf/leftcreatedpdf.component';
import { StudentLedgerPrevDuesComponent } from './pdfreports/studentledgerprevdues/studentledgerprevdues.component';
import { StudentAllHistoryComponent } from './pdfreports/studentallhistory/studentallhistory.component';
import { OpenDuesCreatedPdfComponent } from './pdfreports/openduescreatedpdf/openduescreatedpdf.component';
import { FeeReceiptViewComponent } from './pdfreports/feereceiptview/feereceiptview.component';
import { ForLoanScholarComponent } from './pdfreports/forloanscholar/forloanscholar.component';
import { StudBookCautionPdfComponent } from './pdfreports/studbookcautionpdf/studbookcautionpdf.component';
import { FeeAdjustPdfComponent } from './pdfreports/feeadjustpdf/feeadjustpdf.component';
import { PdcTransComponent } from './masters/pdctrans/pdctrans.component';
import { PdcTransPdfComponent } from './pdfreports/pdctranspdf/pdctranspdf.component';
import { PdcTransDispComponent } from './masters/pdctransdisp/pdctransdisp.component';
import { CreateDuesComponent } from './createdues/createdues.component';
import { StudentMasterComponent } from './masters/studentmaster/studentmaster.component';
import { LeftStudentComponent } from './masters/leftstudent/leftstudent.component';
import { StudentChangeComponent } from './masters/studentchange/studentchange.component';
import { DateChangeSetComponent } from './masters/datechangeset/datechangeset.component';
import { AllowPrevTransEditComponent } from './masters/allowprevtransedit/allowprevtransedit.component';
import { SearchStudentComponent } from './masters/searchstudent/searchstudent.component';
import { TransFeesComponent } from './masters/transfees/transfees.component';
import { FeesAdjustComponent } from './masters/feesadjust/feesadjust.component';
import { LoginUserComponent } from './masters/login/login.component';
import { LogOutUserComponent } from './masters/logout/logout.component';
import { CounterComponent } from './counter/counter.component';
import { FetchDataComponent } from './fetch-data/fetch-data.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FeesComponent } from './Fees/fees.component';
import { AppRoutingModule } from './app-routing.module';
//import { ReportViewerModule } from 'ngx-ssrs-reportviewer';
//import { BoldReportViewerModule } from '@boldreports/angular-reporting-components';
import { TestreportsComponent } from './pdfreports/testreports.component';
import { FeecollectionComponent } from './pdfreports/feecollection/feecollection.component';
import { FeeReConcileComponent } from './pdfreports/feereconcile/feereconcile.component';
import { FeeReConcilePrevComponent } from './pdfreports/feereconcileprev/feereconcileprev.component';
import { DuesPaidPendingComponent } from './pdfreports/duespaidpending/duespaidpending.component';
import { FeecollectionpdfComponent } from './pdfreports/feecollectionpdf/feecollectionpdf.component';
import { FeeCollectionDatesComponent } from './pdfreports/feecollectiondates/feecollectiondates.component';
import { AmountToWordPipe } from './amount-to-word.pipe';
import { Routes, RouterModule } from '@angular/router';
import { NgxPaginationModule } from 'ngx-pagination';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { MatNativeDateModule } from '@angular/material/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatListModule } from '@angular/material/list';
import { MatCardModule } from '@angular/material/card';
import { NgSelectModule } from "@ng-select/ng-select";
import { MatMenuModule } from '@angular/material/menu';
import { CubejsClientModule } from '@cubejs-client/ngx';
import { AgChartsAngularModule } from 'ag-charts-angular';
//import {
//  MatInputModule, MatPaginatorModule, MatProgressSpinnerModule,
//  MatSortModule, MatTableModule
//} from "@angular/material";
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule, MAT_RADIO_DEFAULT_OPTIONS } from '@angular/material/radio';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FlexLayoutModule } from '@angular/flex-layout';
//import { MatSelect } from "@angular/material/select";
////Below list Use when require MatMatrial
//import { NgModule } from '@angular/core';
//import { A11yModule } from '@angular/cdk/a11y';
import { DragDropModule } from '@angular/cdk/drag-drop';
//import { PortalModule } from '@angular/cdk/portal';
//import { ScrollingModule } from '@angular/cdk/scrolling';
//import { CdkStepperModule } from '@angular/cdk/stepper';
//import { CdkTableModule } from '@angular/cdk/table';
//import { CdkTreeModule } from '@angular/cdk/tree';
//import { MatAutocompleteModule } from '@angular/material/autocomplete';
//import { MatBadgeModule } from '@angular/material/badge';
//import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
//import { MatButtonModule } from '@angular/material/button';
//import { MatButtonToggleModule } from '@angular/material/button-toggle';
//import { MatCardModule } from '@angular/material/card';
//import { MatCheckboxModule } from '@angular/material/checkbox';
//import { MatChipsModule } from '@angular/material/chips';
//import { MatStepperModule } from '@angular/material/stepper';
//import { MatDatepickerModule } from '@angular/material/datepicker';
//import { MatDialogModule } from '@angular/material/dialog';
//import { MatDividerModule } from '@angular/material/divider';
//import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
//import { MatIconModule } from '@angular/material/icon';
//import { MatInputModule } from '@angular/material/input';
//import { MatListModule } from '@angular/material/list';
//import { MatMenuModule } from '@angular/material/menu';
//import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
//import { MatPaginatorModule } from '@angular/material/paginator';
//import { MatProgressBarModule } from '@angular/material/progress-bar';
//import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
//import { MatRadioModule } from '@angular/material/radio';
//import { MatSelectModule } from '@angular/material/select';
//import { MatSidenavModule } from '@angular/material/sidenav';
//import { MatSliderModule } from '@angular/material/slider';
//import { MatSlideToggleModule } from '@angular/material/slide-toggle';
//import { MatSnackBarModule } from '@angular/material/snack-bar';
//import { MatSortModule } from '@angular/material/sort';
//import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
//import { MatToolbarModule } from '@angular/material/toolbar';
//import { MatTooltipModule } from '@angular/material/tooltip';
//import { MatTreeModule } from '@angular/material/tree';
//import '@boldreports/javascript-reporting-controls/Scripts/bold.report-viewer.min';
//import '@boldreports/javascript-reporting-controls/Scripts/data-visualization/ej.bulletgraph.min';
//import '@boldreports/javascript-reporting-controls/Scripts/data-visualization/ej.chart.min';
import { MatSelectModule } from '@angular/material/select';
//import { HttpClientModule } from '@angular/common/http';
//import { NgModule } from '@angular/core';
//import { FormsModule, ReactiveFormsModule } from '@angular/forms';
//import { MatNativeDateModule } from '@angular/material/core';
//import { BrowserModule } from '@angular/platform-browser';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
//import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
//import { DemoMaterialModule } from './app/material-module';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { NgOptionHighlightModule } from '@ng-select/ng-option-highlight';
//import { SelectOverviewExample } from './app/select-overview-example';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { SpinnerComponent } from './loader/spinner.component';
import { LoadingInterceptor } from './loader/loading.interceptor';
import { MaterialDashboardComponent } from './material-dashboard/material-dashboard.component';
import { MatIconModule } from '@angular/material/icon';
import { NgChartsModule } from 'ng2-charts';
import * as CanvasJSAngularChart from 'src/assets/canvasjs.angular.component';
var CanvasJSChart = CanvasJSAngularChart.CanvasJSChart;
//import { RecaptchaModule } from 'ng-recaptcha';
//import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from "ng-recaptcha";


const appRoutes: Routes = [
 /* { path: '', component: TransFeesComponent, pathMatch: 'full' },  */
  { path: 'studentmaster', component: StudentMasterComponent },
  { path: 'studentchange', component: StudentChangeComponent },
  { path: 'student-fees', component: FeesComponent },
  { path: 'test-reports', component: TestreportsComponent },
  { path: 'fee-collection', component: FeecollectionComponent },
  { path: 'fee-collectionpdf', component: FeecollectionpdfComponent },
  { path: 'fee-collectiondates', component: FeeCollectionDatesComponent },
  { path: 'createdues', component: CreateDuesComponent },
  { path: 'dues-paidpending', component: DuesPaidPendingComponent },
  { path: 'student-ledgerpdf', component: StudentLedgerpdfComponent },
  { path: 'individualstudent-ledgerpdf', component: IndividualStudentLedgerpdfComponent },
  { path: 'studmobiledetpdf', component: StudentMobileDetpdfComponent },
  { path: 'loginuser', component: LoginUserComponent },
  { path: 'logoutuser', component: LogOutUserComponent },
  { path: 'material-dashboard', component: MaterialDashboardComponent },
  { path: 'feereceiptview', component: FeeReceiptViewComponent },
  { path: 'duescreatedpdf', component: DuesCreatedPdfComponent },
  { path: 'openduescreatedpdf', component: OpenDuesCreatedPdfComponent },
  { path: 'fee-reconcilepdf', component: FeeReConcileComponent },
  { path: 'datechangeset', component: DateChangeSetComponent },
  { path: 'searchstudent', component: SearchStudentComponent },
  { path: 'transfees', component: TransFeesComponent },
  { path: 'feesadjust', component: FeesAdjustComponent },
  { path: 'leftstudent', component: LeftStudentComponent },
  { path: 'leftcreatedpdf', component: LeftCreatedPdfComponent },
  { path: 'feeadjustpdf', component: FeeAdjustPdfComponent },
  { path: 'pdctrans', component: PdcTransComponent },
  { path: 'pdctransdisp', component: PdcTransDispComponent },
  { path: 'pdctranspdf', component: PdcTransPdfComponent },
  { path: 'forloanscholar', component: ForLoanScholarComponent },
  { path: 'studbookcautionpdf', component: StudBookCautionPdfComponent },
  { path: 'feereconcileprev', component: FeeReConcilePrevComponent },
  { path: 'studentledgerprevdues', component: StudentLedgerPrevDuesComponent },
  { path: 'studentallhistory', component: StudentAllHistoryComponent },
  { path: 'allowprevtransedit', component: AllowPrevTransEditComponent },
  { path: 'boygirldetaillist', component: BoyGirlDetailListComponent }, 
];

@NgModule({
  declarations: [
    AppComponent,    
    CreateDuesComponent,
    StudentMasterComponent,
    LeftStudentComponent,
    BoyGirlDetailListComponent,
    StudentChangeComponent,
    StudBookCautionPdfComponent,
    TransFeesComponent,
    PdcTransComponent,
    PdcTransDispComponent,
    FeesComponent,
    StudentAllHistoryComponent,
    StudentLedgerPrevDuesComponent,
    SearchStudentComponent,
    FeeReConcilePrevComponent,
    LogOutUserComponent,
    FeesAdjustComponent,
    PdcTransPdfComponent,
    //DemoMaterialModule,
    NavMenuComponent,
    HomeComponent,
    AllowPrevTransEditComponent,
    FeeReConcileComponent,
    DuesCreatedPdfComponent,
    OpenDuesCreatedPdfComponent,
    CounterComponent,
    ForLoanScholarComponent,
    CanvasJSChart,
    LoginUserComponent,
    MaterialDashboardComponent,
    FeeAdjustPdfComponent,
    TestreportsComponent,
    FeecollectionComponent,
    FeecollectionpdfComponent,
    FeeCollectionDatesComponent,
    DuesPaidPendingComponent,
    DateChangeSetComponent,
    LeftCreatedPdfComponent,
    FeeReceiptViewComponent,
    StudentLedgerpdfComponent,
    IndividualStudentLedgerpdfComponent,
    StudentMobileDetpdfComponent,
    FetchDataComponent, AmountToWordPipe
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    ReactiveFormsModule,
    CubejsClientModule,
    FormsModule,
    AgChartsAngularModule,
    NgMultiSelectDropDownModule,
    NgOptionHighlightModule,
    NgSelectModule,
    MatSelectModule,
    MatGridListModule,
    NgChartsModule,
    MatNativeDateModule,
    MatTableModule,    
    MatPaginatorModule,
    FlexLayoutModule,
    MatSortModule,
    MatRadioModule,
    MatIconModule,
    MatInputModule,
    MatCheckboxModule,
    MatListModule,
    MatCardModule,
    DragDropModule,
    //BoldReportViewerModule,
    MatButtonModule,
    MatToolbarModule,
    MatTabsModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    NgxPaginationModule,
    AppRoutingModule,
    MatMenuModule,
    MatIconModule,
    Ng2SearchPipeModule,
    RouterModule.forRoot(appRoutes)
  ],
  entryComponents: [
  ],
  providers: [
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill' } },
    { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true }
    /*{ provide: MAT_RADIO_DEFAULT_OPTIONS, useValue: { color: 'primary' } }*/

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
