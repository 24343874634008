<!--<button routerLink='/student/student-master'>red</button>
<button routerLink='/Fees/student-fees'>blue</button>
<router-outlet></router-outlet>-->
<!doctype html>
<html lang="en">
<head>
  <title>Welcome to Fee Management System</title>
  <meta charset="UTF-8" />
  <meta http-equiv="X-UA-Compatible" content="IE=edge" />
  <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.4.1/font/bootstrap-icons.css" />
  <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/fontawesome/4.7.0/css/font-awesome.min.css">

  <!--<link href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.1/dist/css/bootstrap.min.css" rel="stylesheet">-->
  <!--These are main two for sidebar hide or visible-->
  <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0-alpha2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-aFq/bzH65dt+w6FI2ooMVUpc+21e0SRygnTpmBvdBgSdnuTN7QbdgL+OapgHtvPp" crossorigin="anonymous">
  <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0-alpha2/dist/js/bootstrap.bundle.min.js" integrity="sha384-qKXV1j0HvMUeCBQ+QVp7JcfGl760yU08IQ+GpUo5hlbpg51QRiuqHAJz8+BrxE/N" crossorigin="anonymous"></script>  
</head>
<body>
  <div class="menu-bar">
    <ul>
      <li class="active"><a href="#">Home</a></li>
      <li><a href="#">About Us</a></li>
      <li>
        <a href="#"><i class="fa-solid fa-house-user"></i>Masters</a>
        <div class="sub-menu-1">
          <ul>
            <li><a routerLink='/masters/studentmaster/studentmaster'>Student Master (Add/Edit)</a></li>
          </ul>
        </div>
      </li>
      <li><a routerLink='/masters/transfees/transfees'>Annual Fee TRXN.</a></li>
      <li><a routerLink='/createdues/createdues'>Fast/Create Dues TRXN.</a></li>
      <li>
        <a href="#">Utilities</a>
        <div class="sub-menu-1">
          <ul>
            <li><a routerLink='/pdfreports/duescreatedpdf/duescreatedpdf'>Delete Created Dues</a></li>
            <li><a routerLink='/pdfreports/openduescreatedpdf/openduescreatedpdf'>Delete Opening Dues</a></li>
            <li><a routerLink='/masters/feesadjust/feesadjust'>Fees Adjust</a></li>
            <li><a routerLink='/masters/pdctrans/pdctrans'>PDC Trans</a></li>
            <li><a routerLink='/masters/studentchange/studentchange'>Fast Enrolment Change</a></li>
            <li><a routerLink='/masters/leftstudent/leftstudent'>Left Student</a></li>
            <li><a routerLink='/masters/datechangeset/datechangeset'>Set Date Change</a></li>
            <li><a routerLink='/masters/allowprevtransedit/allowprevtransedit'>AllowPrevTrans. Edit</a></li>
          </ul>
        </div>
      </li>
      <li>
        <a href="#"><i class="fa-solid fa-house-user"></i>Reports-1</a>
        <div class="sub-menu-1">
          <ul>
            <li><a routerLink='/masters/login/loginuser'>Login</a></li>
            <li><a routerLink='/material-dashboard/material-dashboard'>Dashboard</a></li>
            <li><a routerLink='/pdfreports/feecollectiondates/fee-collectiondates'>Daily Fee Collection</a></li>
            <li><a routerLink='/pdfreports/studentledgerpdf/student-ledgerpdf'>Course/Year Wise Student Ledger</a></li>
            <li><a routerLink='/pdfreports/individualstudentledgerpdf/individualstudent-ledgerpdf'>Individual Student Ledger</a></li>
            <li><a routerLink='/pdfreports/studentallhistory/studentallhistory'>Student All History</a></li>
            <li><a routerLink='/pdfreports/feereceiptview/feereceiptview'>Student Fee Receipt</a></li>
            <li><a routerLink='/pdfreports/studbookcautionpdf/studbookcautionpdf'>New Student BBank/Caution</a></li>
            <li><a routerLink='/pdfreports/duespaidpending/dues-paidpending'>Dues Paid/Pending</a></li>
          </ul>
        </div>
      </li>
      <li>
        <a href="#"><i class="fa-solid fa-house-user"></i>Reports-2</a>
        <div class="sub-menu-1">
          <ul>
            <li><a routerLink='/pdfreports/studmobiledet/studmobiledetpdf'>Student Basic Detail</a></li>
            <li><a routerLink='/pdfreports/boygirldetaillist/boygirldetaillist'>Student Boy/Girl List</a></li>
            <li><a routerLink='/pdfreports/feereconcile/fee-reconcilepdf'>Fee Re-Conciliation</a></li>
            <!--<li><a routerLink='/pdfreports/feereconcileprev/feereconcileprev'>Fee Re-Conciliation (Prev Dues)</a></li>-->
            <li><a routerLink='/pdfreports/studentledgerprevdues/studentledgerprevdues'>Prev.Dues Update Status</a></li>
            <li><a routerLink='/pdfreports/feeadjustpdf/feeadjustpdf'>Fee Adjusted (By DSGMC)</a></li>
            <li><a routerLink='/pdfreports/pdctranspdf/pdctranspdf'>PDC Status</a></li>
            <li><a routerLink='/pdfreports/leftcreatedpdf/leftcreatedpdf'>Left Students Details</a></li>
            <li><a routerLink='/pdfreports/forloanscholar/forloanscholar'>Loan/Scholar</a></li>
          </ul>
        </div>
      </li>
      <li><a routerLink='/pdfreports/feereceiptview/feereceiptview'>Fee Receipt</a></li>
      <li><a routerLink='/masters/searchstudent/searchstudent'>Search Student</a></li>
      <li>
        <div id="topnav-right">
          <h1 ng-model="UserDD"></h1>
          <h5>{{GLBappInstName}}</h5>
          <h6>Your IpAddress: {{IPAddress}}</h6>
          <h6>A/Year {{GLBappFYearCode}}-{{GLBappFYearCodeC}} (Logged In As:{{GLBappUserName}})</h6>
        </div>
      </li>
    </ul>
  </div>
  <div class="container">
    <router-outlet></router-outlet>
  </div>
  <div class="offcanvas sidebar-nav bg-dark" tabindex="-1" id="sidebar">
    <div class="offcanvas-body p-0">
      <nav class="navbar-dark">
        <ul class="navbar-nav">
          <li>
            <div class="text-muted small fw-bold text-uppercase px-3">

            </div>
          </li>
          <li>
            <a routerLink='/material-dashboard/material-dashboard' class="nav-link px-3 active">
              <span class="me-2"><i class="bi bi-speedometer2"></i></span>
              <span>Dashboard</span>
            </a>
          </li>
          <li class="my-4"><hr class="dropdown-divider bg-light" /></li>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <li>
            <span style="color:white;font-size:16px">&nbsp;&nbsp;&nbsp;&nbsp;Select A/Year</span>
            <div class="radio">
              <input id="radio-1" name="radio" value="0" type="radio" (change)="radioSelectedOrder($event.target.value)" checked="{{checked2024}}">
              <label for="radio-1" class="radio-label"><span style="font-size:14px;color:white">{{getFyYear[0].startEnd}}</span></label>
            </div>

            <div class="radio">
              <input id="radio-2" name="radio" value="1" type="radio" (change)="radioSelectedOrder($event.target.value)" checked="{{checked2023}}">
              <label for="radio-2" class="radio-label"><span style="font-size:14px;color:white">{{getFyYear[1].startEnd}}</span></label>
            </div>
          </li>
            <!--<div class="custom-control custom-radio">
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<input id="morning" type="radio" class="custom-control-input" [(ngModel)]="selectedOrder" name="ShiftSelect" value="0" (change)="radioSelectedOrder($event.target.value)" />
    &nbsp;&nbsp;<label for="morning"><span style="font-size:14px;color: white">{{getFyYear[0].startEnd}}</span></label><br />
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<input id="evening" type="radio" [(ngModel)]="selectedOrder" name="ShiftSelect" value="1" (change)="radioSelectedOrder($event.target.value)" />
    &nbsp;&nbsp;<label for="evening"><span style="font-size:14px;color:white">{{getFyYear[1].startEnd}}</span></label>
    </div>-->
          <!--<li>-->
          <!--<a class="nav-link px-3 sidebar-link"
     data-bs-toggle="collapse"
     href="#layouts">
    <span class="me-2"><i class="bi bi-layout-split"></i></span>
    <span>Time Attendance</span>
    <span class="ms-auto">
      <span class="right-icon">
        <i class="bi bi-chevron-down"></i>
      </span>
    </span>
  </a>-->
          <!--<div class="collapse" id="layouts">
      <ul class="navbar-nav ps-3">
        <li>
          <a href="#" class="nav-link px-3">
            <span class="me-2"><i class="bi bi-speedometer2"></i></span>
            <span>Student</span>
          </a>
        </li>
      </ul>
    </div>
    <div class="collapse" id="layouts">
      <ul class="navbar-nav ps-3">
        <li>
          <a href="#" class="nav-link px-3">
            <span class="me-2"><i class="bi bi-speedometer2"></i></span>
            <span>Teachers/Professors</span>
          </a>
        </li>
      </ul>
    </div>
  </li>

  <li class="my-4"><hr class="dropdown-divider bg-light" /></li>
  <li>
    <div class="text-muted small fw-bold text-uppercase px-3 mb-3">
      Addons
    </div>
  </li>-->
          <li>
            <a routerLink='/material-dashboard/material-dashboard' class="nav-link px-3">
              <span class="me-2"><i class="bi bi-graph-up"></i></span>
              <span>Refresh Page</span>
            </a>
          </li>
          <!--<li>
    <a href="#" class="nav-link px-3">
      <span style="color:white">{{getFyYear[1].yearCode}}</span>
    </a>
  </li>-->
          <li>
            <a routerLink='/masters/logout/logoutuser' class="nav-link px-3">
              <span class="me-2"><i class="bi bi-box-arrow-right"></i></span>
              <span>Logout</span>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</body>
</html>
